<template>
  <ion-fab
    v-if="whappyClientConfig.name == 'Yoube' && has_customer"
    vertical="bottom"
    horizontal="end"
  >
    <!-- <ion-fab-button
          @click="openModalNewKpiChat"
          class="ion-margin-end ion-margin-bottom"
        >
          <ion-icon :icon="cart"></ion-icon>
        </ion-fab-button> -->
    <ion-fab-button
      @click="
        () => {
          $router.push('/shopping-cart');
          closeModal();
        }
      "
      class="ion-margin-end ion-margin-bottom"
    >
      <ion-icon :icon="cart"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <master-layout
    :showContentHeader="false"
    :showHeader="false"
    :noPadding="true"
  >
    <div v-if="has_customer" class="page">
      <div @click="closeModal" class="ion-padding text-18">
        <ion-icon :icon="arrowBackOutline"></ion-icon>
      </div>
      <div class="dettaglio-prodotto">
        <img
          class="border-radius-client"
          :src="item.pic"
          @error="handleImgError"
        />
        <div class="info-prodotto">
          <div class="prezzo-e-carrello">
            <div class="price">
              <span>€ {{ " " + item.price }}</span>
            </div>
            <div v-if="!aggiungiDisabilitato" class="counter">
              <span @click="substractOneItem(item)" class="remove">-</span>
              <span class="actual">{{ counter }}</span>
              <span @click="addOne" class="add">+</span>
            </div>
          </div>
          <div class="descrizione-prodotto">
            <div class="titolo">{{ item.name }}</div>
            <div class="descrizione">{{ item.description }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div @click="closeModal" class="ion-padding text-18">
        <ion-icon :icon="arrowBackOutline"></ion-icon>
      </div>

      <div
        class="text-align-center mb-4 mt-4 pb-4 px-4 text-color-primary font-bold text-18"
      >
        Prima di procedere a popolare il carrello, per favore seleziona un
        cliente
      </div>

      <div class="flex flex-column px-4 py-1 seleziona-container">
        <div>
          <!-- <span class="text-14 font-bold mb-4">Seleziona il cliente</span> -->
          <div class="w-full my-4">
            <input
              class="w-full"
              v-model="filter"
              placeholder="Cerca cliente..."
            />
          </div>
          <ion-row
            class="items ion-padding-horizontal ion-margin-bottom ion-margin-top ion-margin-top cursor-pointer"
            v-for="item of filteredItems"
            :key="item.id"
            @click="selezionaCliente(item)"
          >
            <ion-col>
              <ion-row
                ><span class="font-bold">{{
                  item.ragioneSociale
                }}</span></ion-row
              >
              <ion-row> {{ item.nome }} {{ item.cognome }} </ion-row>
              <ion-row>
                <ion-col>
                  <gradient-progress-bar
                    class="height-5 gradient"
                    :value="item.hotness"
                  ></gradient-progress-bar>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col class="ion-text-end ion-padding-top" size="2"> </ion-col>
          </ion-row>
        </div>
      </div>
    </div>
  </master-layout>

  <!-- <ion-button expand="block" @click="closeModal"> Close</ion-button> -->
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelect,
  IonSelectOption,
  modalController,
  IonIcon,
} from "@ionic/vue";
import { arrowBackOutline, cart } from "ionicons/icons";
import DettaglioOrdine from "./DettaglioOrdine.vue";
import ShoppingCartService from "../../common/mixins/ShoppingCart.vue";
import ApiService from "../../common/service.api";
import { useStore } from "vuex";
export default {
  name: "singleItem",
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonSelect,
    IonSelectOption,
    modalController,
    IonIcon,
  },
  mixins: [ShoppingCartService],
  props: {
    item: Object,
  },
  created() {
    this.currentCart = useStore().state.shoppingCart.currentCart;

    if (this.$store.getters["shoppingCart/getCurrentCart"].customer.id > 0) {
      this.has_customer = true;
    } else {
      this.has_customer = false;
    }

    this.getData();
  },
  data() {
    return {
      currentCart: {},
      filter: "",
      currentCustomer: {},
      has_customer: false,
      cart,
      arrowBackOutline,
      counter: 0,
      clientiOptions: [],
      objectToPost: {
        idTipoAnagrafica: 0,
        idUtente: 0,
        idPadre: 0,
        nome: "",
        cognome: "",
        ragioneSociale: "",
        partitaIva: "",
        codiceFiscale: "",
        email: "",
        telefono: "",
        cellulare: "",
        citta: "",
        provincia: "",
        regione: "",
        nazione: "",
        note: "",
        anagraficaYoube: true,
      },
    };
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    getData() {
      ApiService.post("Ecommerce/GetAnags", this.objectToPost).then((res) => {
        this.clientiOptions = res.data;
      });
    },
    selezionaCliente(customer) {
      this.has_customer = true;
      this.setCustomer(customer);
    },
    substractOneItem(item) {
      this.substractOne(item);
      this.counter--;
    },
    addOne() {
      let currentItem = { idProdotto: this.item.id, ...this.item };
      currentItem.number = 1;
      this.addItem(currentItem);
      this.counter++;
    },
    closeModal() {
      modalController.dismiss();
    },
    dettaglioOrdine() {
      // modalController.dismiss();
      this.openModal(this.item, this.counter);
    },
    async openModal(item, counter) {
      let cliente = {
        name: "Mario",
        surname: "Rossi",
        telefono: "3435095885",
        pIva: "1432r143093025",
      };

      const modal = await modalController.create({
        component: DettaglioOrdine,
        cssClass: "my-custom-class",
        componentProps: {
          item: item,
          counter: counter,
          cliente: cliente,
        },
      });
      return modal.present();
    },
  },
  computed: {
    aggiungiDisabilitato() {
      if (this.currentCart.items.length == 0) return false;
      let firstItem = this.currentCart.items[0];
      if (firstItem.isContovendita) return true;
      return false;
    },
    filteredItems() {
      if (this.filter) {
        return this.clientiOptions.filter((item) => {
          return (
            item.nome.toLowerCase().includes(this.filter.toLowerCase()) ||
            item.cognome.toLowerCase().includes(this.filter.toLowerCase()) ||
            item.ragioneSociale
              .toLowerCase()
              .includes(this.filter.toLowerCase())
          );
        });
      } else {
        return this.clientiOptions;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* * {
  border: 1px solid pink;
} */

.counter {
  display: flex;
  align-items: center;
  margin: 0.5rem;

  background: var(--client-ground1-color);
  border-radius: var(--ion-border-radius);
}

.counter ion-col {
  display: grid;
  place-items: center;
  padding: 0 1rem;
}

.promo-activated {
  /* background: var(--ion-color-CTA);
  color: var(--ion-color-secondary); */

  background: var(--ion-color-primary);
  color: #fff;
}

.page {
  background: var(--client-ground2-color) !important;
  height: 100vh;

  .dettaglio-prodotto {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    .info-prodotto {
      display: flex;
      flex-direction: column;
      .prezzo-e-carrello {
        padding-top: 1rem;
        display: flex;
        justify-content: space-between;
        font-size: 22px;
        .price {
          font-weight: bolder;
          color: var(--ion-color-primary);
          padding: 1rem 0;
        }
        .counter {
          background: var(--ion-color-secondary);
          border-radius: var(--ion-border-radius);
          display: flex;
          align-items: center;
          justify-content: space-between;
          min-width: 110px;
          padding: 0.5rem 1rem;
        }
      }
      .descrizione-prodotto {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        .titolo {
          padding-bottom: 1rem;
          font-weight: bold;
          font-size: 24px;
        }
      }
    }
  }
}

.seleziona-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: var(--client-ground2-color);
  padding: 50px 0;
  height: calc(100vh - 165px);
}
</style>
